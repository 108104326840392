import isEqual from 'lodash.isequal';
import { useEffect, useState } from 'react';

import {
  stringifyUrl,
  parseQueryString,
  replaceRouteWithoutReRender
} from '@utils/router';
import {
  toLowerDashedCase,
  removeAccentsMark,
  removeSpecialCharacters
} from '@utils/strings';
import useConfig from './useConfig';
import en from '@config/translations/lang/en';
import es from '@config/translations/lang/es';
import { isClientSide } from '@utils/validations';

export const cleanKey = (title) =>
  toLowerDashedCase(removeSpecialCharacters(removeAccentsMark(title)));

const getContraryQueryParamName = ({ language, queryParamKey }) =>
  language === 'en' ? es[queryParamKey] : en[queryParamKey];

const getQuery = (selectedTabsKeys, translatedQueryParamName = 'tab') => {
  const { query } = selectedTabsKeys.reduce(
    (accumulator, selectedTabKey, index) => {
      if (!selectedTabKey || accumulator.stop) {
        return {
          stop: true,
          query: {}
        };
      }
      if (index > 0) {
        accumulator.query[translatedQueryParamName] += '_';
      }
      accumulator.query[translatedQueryParamName] += cleanKey(selectedTabKey);

      return accumulator;
    },
    { query: { [translatedQueryParamName]: '' }, stop: false }
  );
  return query;
};

const getTabsKeysFromUrl = (router = {}, translatedQueryParamName = 'tab') =>
  router?.query[translatedQueryParamName]?.split('_') || [];

const thereAreQueryParams = (query) => Object.keys(query).length > 0;

const areNewQueryTab = (newQueryTab, queryTabFromUrl) =>
  newQueryTab !== queryTabFromUrl?.join('_');

const haveToReplaceUrl = ({
  tabsKeysFromUrl,
  query,
  translatedQueryParamName = 'tab'
}) =>
  !!(
    thereAreQueryParams(query) &&
    areNewQueryTab(query[translatedQueryParamName], tabsKeysFromUrl)
  );

export const getDefaultTabFromUrl = ({
  router,
  tabsKeys,
  level,
  fallbackDefaultTabIndex = 0,
  translatedQueryParamName = 'tab'
}) => {
  const tabsKeysFromUrl = getTabsKeysFromUrl(router, translatedQueryParamName);
  const tabKeyFromUrl = tabsKeysFromUrl[level] || '';

  const tabIndex = tabsKeys.findIndex(
    (tabKey) => cleanKey(tabKey) === tabKeyFromUrl
  );
  return tabIndex === -1 ? fallbackDefaultTabIndex : tabIndex;
};

export const getTabsKeys = (arr = [], key = '') => arr.map((item) => item[key]);

const useDefaultTabFromUrl = (
  selectedTabsKeys,
  { translatedQueryParamName = 'tab', queryParamKey, isDisabled = false } = {}
) => {
  const [{ language }] = useConfig();
  const [prevLanguage, setPrevLanguage] = useState(language);

  useEffect(() => {
    if (!isDisabled) {
      if (prevLanguage === language && isClientSide()) {
        const parsedLocationSearch = parseQueryString(window.location.search);
        const tabsKeysFromUrl =
          parsedLocationSearch[translatedQueryParamName]?.split('_');
        const query = getQuery(selectedTabsKeys, translatedQueryParamName);

        if (
          haveToReplaceUrl({
            tabsKeysFromUrl,
            query,
            translatedQueryParamName
          })
        ) {
          const contraryQueryParamName = getContraryQueryParamName({
            language,
            queryParamKey
          });

          const newLocationSearch = {
            ...parsedLocationSearch,
            ...query
          };

          if (
            contraryQueryParamName &&
            parsedLocationSearch[contraryQueryParamName]
          )
            delete newLocationSearch[contraryQueryParamName];

          if (!isEqual(parsedLocationSearch, newLocationSearch)) {
            replaceRouteWithoutReRender(
              stringifyUrl({
                url: `${window.location.origin}${window.location.pathname}`,
                query: newLocationSearch
              })
            );
          }
        }
      } else {
        setPrevLanguage(language);
      }
    }
  }, [isDisabled, selectedTabsKeys, queryParamKey]);
};

export default useDefaultTabFromUrl;
