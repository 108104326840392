import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import {
  FancyLine,
  Scrollbar,
  Typography
} from '@bvcco/bvc-digital-market-component-library';

import {
  StyledSelect,
  StyledContainer,
  StyledEventsContainer,
  StyledNoEventsContainer
} from './Events.styled';
import App from '@config/app.js';
import useConfig from '@hooks/useConfig';
import Event from '@components/Event/Event';
import { cmsLocales } from '@config/constants';
import { useMobile } from '@hooks/useBreakpoint';
import Calendar from '@components/Calendar/Calendar';
import { inputOptionsProps } from '@config/common-propTypes';
import ButtonLines from '@components/ButtonLines/ButtonLines';
import { getDateCeroHours, getDateTwentyFourHours } from '@utils/dates';

const Events = ({
  content,
  filters,
  updateFilters,
  filtersOptions,
  upcomingEvents,
  eventsSelectedDay
}) => {
  const isMobile = useMobile();
  const { t } = useTranslation();
  const [{ language }] = useConfig();
  const [localEvents, setLocalEvents] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);

  const eventsDates = upcomingEvents.map((event) => event.date);

  useEffect(() => {
    setLocalEvents(eventsSelectedDay);
  }, [eventsSelectedDay]);

  useEffect(() => {
    const fetchEvents = async () => {
      const { cms } = App.getInstance();
      const newEvents = await cms.getEvents(cmsLocales[language], filters);
      setLocalEvents(newEvents);
    };
    fetchEvents();
  }, [filters]);

  useEffect(() => {
    setSelectedCategories((prevState) =>
      prevState.map(({ value }) =>
        filtersOptions.find((option) => value === option.value)
      )
    );
  }, [language, filtersOptions]);

  const renderEvents = () =>
    localEvents.map((event, index) => (
      <Event
        active={index === 0}
        isMobile={isMobile}
        event={event}
        key={event.name + index}
      />
    ));

  const onChangeEventCategories = (eventCategories) => {
    const allEvents = eventCategories ? 'none' : '';
    const categoriesIds = eventCategories
      ? eventCategories.map(({ value }) => value)
      : [''];

    updateFilters((prevState) => ({
      ...prevState,
      allEvents,
      categoriesIds
    }));
    setSelectedCategories(eventCategories || []);
  };

  const onChangeCalendarDay = (date) => {
    const initialDate = getDateCeroHours(date);
    const finalDate = getDateTwentyFourHours(date);
    updateFilters((prevState) => ({ ...prevState, initialDate, finalDate }));
  };

  return (
    <StyledContainer>
      <Typography type='h5' color='tertiary' as='h2'>
        {content.title}
      </Typography>
      <FancyLine />
      <Grid className='calendar-events' fluid>
        <Row>
          <Col md={5} lg={4}>
            <StyledSelect
              id='date-select'
              onFocus={() => {
                setShowCalendar(true);
              }}
              placeholder={t('date')}
              placeholderColor='secondary'
              isSearchable={false}
              colorIndicators='tertiary'
              valueFontColor='secondary'
            />
            {showCalendar && (
              <Calendar
                id='react-calendar'
                locale={language}
                events={eventsDates}
                onChange={onChangeCalendarDay}
                onClose={() => {
                  setShowCalendar(false);
                }}
              />
            )}
          </Col>
          <Col xs={12} md={7} lg={8}>
            <StyledSelect
              onChange={onChangeEventCategories}
              options={filtersOptions}
              maxSelectedItems={3}
              placeholder={t('allEvents')}
              placeholderColor='secondary'
              isSearchable={false}
              colorIndicators='tertiary'
              multiValueLabelMaxLength={{
                xs: '54px',
                sm: '120px',
                md: '90px',
                lg: '120px'
              }}
              isMulti
              valueFontColor='secondary'
              value={selectedCategories}
              maxSelectedItemsLabel={t('maxSelectedItemsError')}
            />
            <StyledEventsContainer>
              <Scrollbar className='scroll'>
                {localEvents.length > 0 ? (
                  renderEvents()
                ) : (
                  <StyledNoEventsContainer>
                    <Typography color='tertiary'>
                      {t('noEventsFound')}
                    </Typography>
                  </StyledNoEventsContainer>
                )}
              </Scrollbar>
            </StyledEventsContainer>
          </Col>
        </Row>
      </Grid>
      <ButtonLines size='large'>{content.buttonMore.text}</ButtonLines>
    </StyledContainer>
  );
};

Events.propTypes = {
  upcomingEvents: PropTypes.arrayOf(Event.propTypes.event).isRequired,
  eventsSelectedDay: PropTypes.arrayOf(Event.propTypes.event).isRequired,
  filtersOptions: PropTypes.arrayOf(PropTypes.shape(inputOptionsProps))
    .isRequired,
  filters: PropTypes.shape({
    categoriesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    initialDate: PropTypes.string.isRequired,
    finalDate: PropTypes.string.isRequired,
    allEvents: PropTypes.string.isRequired
  }).isRequired,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    buttonMore: PropTypes.shape({
      text: PropTypes.string.isRequired,
      redirectTo: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  updateFilters: PropTypes.func.isRequired
};

Events.defaultProps = {};

export default Events;
