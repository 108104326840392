import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${({ theme, withoutPadding }) =>
    withoutPadding ? theme.utils.spacing(0) : theme.utils.spacing(15, 10)};
  background: url('${({ img }) => img}');
  background-size: cover;
  border-radius: ${({ borderRadius, theme }) =>
    theme.borderRadius[borderRadius]};
  background-repeat: no-repeat;
  height: ${({ theme, withoutPadding }) =>
    withoutPadding ? '100%' : `calc(100% - ${theme.utils.spacing(30)})`};
  ${({ enabledEffects }) =>
    enabledEffects &&
    css`
      :after {
        content: ' ';
        border-radius: ${({ borderRadius, theme }) =>
          theme.borderRadius[borderRadius]};
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${({ theme, color, colorType }) =>
          theme.colors.bg[color][colorType]};
        opacity: 0.6;
      }
      :before {
        content: ' ';
        border-radius: ${({ borderRadius, theme }) =>
          theme.borderRadius[borderRadius]};
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${({ theme, baseColor, baseColorType }) =>
          theme.colors.bg[baseColor][baseColorType]};
        opacity: 0.2;
      }
    `}
`;

export const StyledChild = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.forward};
`;
