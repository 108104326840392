export default {
  accesibilityBar: {
    virtualOffice: 'Oficina virtual'
  },
  name: 'Nombre',
  registrationYear: 'Año de inscripción',
  listingDate: 'Fecha listado',
  industry: 'Industria',
  city: 'Ciudad',
  marketCap: 'Capitalización Bursátil',
  showMore: 'Ver más',
  country: 'País de Origen',
  countryETF: 'País',
  couponFrequenciesRfPriv: {
    none: '-',
    daily: 'Diaria',
    monthly: 'Mensual',
    bimonthly: 'Bimestral',
    quarterly: 'Trimestral',
    everyFourMonths: 'Cuatrimestral',
    semiannual: 'Semestral',
    annual: 'Anual',
    biannual: 'Bianual',
    periodic: 'Periódica'
  },
  mainStockExchange: 'Bolsa de Origen',
  sponsorName: 'Patrocinador',
  allEvents: 'Todos los eventos',
  closedMarket: 'Mercado cerrado',
  closingPrice: 'Precio de cierre',
  contactOur: 'Comunícate con nuestro Centro de Información Bursátil al',
  date: 'Fecha',
  delay: 'Retraso',
  deleteFilters: 'Borrar filtros',
  emailValidation: 'El correo debe contener @ y .com',
  dataProcessingPoliciesValidation:
    'Debes aceptar la política de tratamientos de datos para continuar',
  days: 'Días',
  contactFormIsRequired:
    'Por favor completa este campo, para BVC es importante conocer tu solicitud a detalle, así podremos brintarte el soporte adecuado.',
  captchaIsRequired: 'El captcha es requerido',
  emailIsRequired: 'Ingresa tu correo electrónico',
  lastNameIsRequired: 'Ingresa tu apellido',
  nameIsRequired: 'Ingresa tu nombre',
  fieldsRequiredContactUs:
    'Los datos marcados con asterisco (*) son obligatorios.',
  onlyLetters: 'Este campo debe contener solo letras',
  onlyNumbers: 'Este campo debe contener solo números',
  nameRequired: 'Este campo es requerido',
  invalidCharacters: 'Este campo contiene caracteres no permitidos',
  intervalFilters: {
    '1D': '1D',
    '1S': '1S',
    '1M': '1M',
    '3M': '3M',
    '6M': '6M',
    '1A': '1A',
    '5A': '5A',
    Max: 'Máx'
  },
  tooLongField: 'Este campo supera el máximo de caracteres',
  filter: 'Filtrar',
  filters: 'Filtros',
  findIssuer: 'Busca el emisor',
  findIssuerOrMnemonic: 'Busca el emisor o el nemotécnico',
  findTitleOrWord: 'Buscar por título o palabra clave',
  find: 'Buscar',
  findIssuerOrMnemonicRV: 'Busca el emisor, nombre o el nemotécnico',
  findIssuerOrUnderlying: 'Busca el nemotécnico o subyacente',
  home: 'Inicio',
  hour: 'Hora',
  volume: 'Volumen',
  contracts: 'Contratos',
  indexRv: {
    tableColumns: {
      actualValue: 'Valor hoy',
      name: 'Nombre',
      variation: 'Variación'
    },
    tabs: {
      actualValue: 'Valor hoy',
      shareholders: 'Accionarios',
      variacion: 'Variación'
    }
  },
  makeGoodDecision: 'Quiero tomar buenas decisiones',
  maxSelectedItemsError: 'Se ha alcanzado el límite de elementos seleccionados',
  marketType: 'Tipo de mercado',
  minutes: 'minutos',
  mnemonic: 'Nemotécnico',
  month: 'Mes',
  months: {
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre'
  },
  download: 'Descargar',
  downloadPdf: 'Descargar PDF',
  informationOfInterest: 'Información de interés',
  followUs: 'Síguenos en:',
  relatedInvestigations: 'Investigaciones anteriores',
  relatedNews: 'Noticias relacionadas',
  noEventsFound: 'No se encontraron eventos en la fecha seleccionada',
  noRecordFound: 'No existen registros para esta fecha',
  noInformationAvailable: 'No hay información disponible con ese término',
  noFiguresArePresented:
    'No se presentan cifras para mostrarte ya que no hay movimientos en el mercado',
  noFiguresWithSelectedFilter:
    'No se presentan cifras para mostrarte con los filtros seleccionados',
  noRecordsFoundByCategory:
    'No se presentan registros para la categoría seleccionada',
  noTradingDay: 'Día no bursátil',
  noOptions: 'Sin registros',
  online: 'En línea',
  openMarket: 'Mercado abierto',
  operationType: 'Tipo de operación',
  instrumentType: 'Tipo de instrumento',
  optionType: 'Tipo de opción',
  previousClosing: 'Cierre anterior',
  referencePrice: 'Precio de referencia',
  referenceRate: 'Tasa de referencia',
  price: 'Precio',
  prices: 'Precios',
  rate: 'Tasa',
  rateOrPrice: 'Tasa / Precio',
  search: 'Búsqueda',
  simultaneous: 'Simultáneas',
  showMoreDetails: 'Ver más detalles',
  tables: {
    moreInfo: 'Más información',
    thousandsOfMillions: 'Miles de millones'
  },
  thousandsOfDollars: 'USD',
  millionsOfPesos: 'COP',
  today: 'HOY',
  todayIsNonStock: 'Hoy es día no bursátil',
  toDownloadInformation: 'Para descargar información de más de',
  tradedVolume: 'Volumen negociado',
  thereAreNoMovements: 'No hay movimientos en el mercado',
  tradingDay: 'Día bursátil',
  currentTrm: 'TRM vigente',
  trm: 'TRM',
  suppliedBy: 'sumínistrada por',
  typeClass: 'Clase de título',
  valueToday: 'Valor hoy',
  variation: 'Variación',
  yearsOfAntiguaty: 'Años de antiguedad',
  category: 'Categoria',
  openPrice: 'Precio de apertura',
  lastPrice: 'Último precio',
  lastRate: 'Última tasa',
  maxPrice: 'Precio maximo',
  averagePrice: 'Precio promedio',
  minPrice: 'Precio minimo',
  lastVolume: 'Ultimo volumen',
  maxVolume: 'Volumen maximo',
  averageVolume: 'Volumen promedio',
  minVolume: 'Volumen minimo',
  marketSpot: 'Mercado Spot',
  nextDay: 'Mercado next day',
  registrationOperations: 'Operaciones de registro',
  exchangeRate: 'Tasa de cambio',
  settlementDate: 'Fecha de liquidación',
  simultaneas: 'Simultáneas',
  volumeUSD: 'Volume USD',
  volumeCOP: 'Volume COP',
  exchangeMarket: 'Mercado cambiario',
  freeMarket: 'Mercado libre',
  totalVolumeUSD: 'Total Volumen USD',
  totalVolumeCOP: 'Total Volumen COP',
  market: 'Mercado',
  dollar: 'Dólar',
  divisas: 'foreign-exchange',
  'renta-variable': 'variable-income',
  indices: 'indexes',
  'renta-fija': 'fixed-rent',
  indexesPath: '/indices',
  internalError: {
    title: '¡Ups! Ha ocurrido un error inesperado, ',
    description:
      'estamos trabajando para solucionarlo. Vuelve a intentarlo más tarde.'
  },
  derivativesPath: '/derivados',
  derivados: 'derivatives',
  goBack: 'Volver',
  lastNegotiation: 'Última negociación',
  baskets: 'Canastas',
  from: 'Desde',
  to: 'Hasta',
  dateRange: 'Rango de fecha',
  datesRange: 'Rango de fechas',
  generateHistorical: 'Generar historico',
  selectDate: 'Elegir fecha',
  selectDates: 'Elegir fechas',
  marketLvl2Route: '/mercado-local-en-linea',
  derivativesN2: 'derivados_futuros',
  variableIncomeN2: 'renta-variable_mercado-local',
  indexesN2: 'indices_accionarios',
  value: 'Valor',
  yearPlaceholder: 'aaaa',
  graphicDescription:
    'La gráfica se presenta desde el nacimiento del contrato. Las barras te indican el volumen de negociación del título, y su escala está en el eje vertical a mano izquierda.',
  routes: {
    contactanos: 'Contáctanos',
    diasNoBursatiles: 'Días no bursátiles'
  },
  toDownload: 'Para descargar',
  acciones: 'Acciones',
  etfs: 'ETFs',
  by: 'por',
  variableIncomePath: {
    path: '/renta-variable',
    subPaths: {
      local_market: 'mercado-local',
      colombian_global_market: 'mercado-global-colombiano'
    },
    tabs: {
      information: '#informacion',
      summary: '#resumen'
    }
  },
  issuerInformationPath: {
    path: '/informacion-del-emisor'
  },
  fixedRentPath: {
    path: '/renta-fija',
    tab: 'renta-fija',
    subPaths: {
      public_debt_public_segment: 'deuda-publica-segmento-publico',
      public_debt_private_segment: 'deuda-publica-segmento-privado',
      corporate_debt: 'deuda-corporativa'
    }
  },
  dividend: 'Dividendo',
  couponFrequency: {
    N: '-',
    D: 'Diaria',
    Mo: 'Mensual',
    BM: 'Bimensual',
    QR: 'Trimestral',
    FM: 'Cuatrimestral',
    SA: 'Semestral',
    Yr: 'Anual',
    BY: 'Bianual',
    P: 'Periódica'
  },
  newsTab: 'tabNoticias',
  notApply: 'No aplica'
};
