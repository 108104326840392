import qs from 'query-string';

import { isClientSide } from './validations';

export const replaceRouteWithoutReRender = (url) => {
  if (isClientSide()) {
    window.history.replaceState(window.history.state, '', url);
  }
};

export const { stringifyUrl } = qs;

export const parseQueryString = qs.parse;
