import moment from 'moment';

export const getDateCeroHours = (date) =>
  moment(date).set('hours', 0).set('minutes', 0).set('seconds', 0);

export const getDateTwentyFourHours = (date) =>
  moment(date).set('hours', 23).set('minutes', 59).set('seconds', 59);

export const getDateFormat = (date, format, toUTC = false) =>
  toUTC ? moment(date).utc().format(format) : moment(date).format(format);

export const dateComposer = (date, dateLocale, format) =>
  moment(date).locale(dateLocale).format(format);

export const formatEvents = ({ events, locale }) =>
  events.map((event) => dateComposer(event, locale, 'LL'));

export const defaultFormatMonthYear = (dateLocale, date) =>
  dateComposer(date, dateLocale, 'MMMM');

export const defaultFormatShortWeekday = (dateLocale, date) =>
  dateComposer(date, dateLocale, 'dd');

export const validateIsTodayDate = (dateToValidate) =>
  moment(dateToValidate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
