/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import {
  Tab,
  Tabs,
  FancyLine,
  Typography
} from '@bvcco/bvc-digital-market-component-library';

import useDefaultTabFromUrl, {
  getDefaultTabFromUrl,
  getTabsKeys
} from '@hooks/useDefaultTabFromUrl';
import Ticker from '@components/Ticker/Ticker';
import useRouter from '@hooks/useRouter';
import IndexesTab from './Tabs/Indexes/Tab';
import FixedRentTab from './Tabs/FixedRent/Tab';
import StyledContainer from './MarketLevel1.styled';
import DerivativesTab from './Tabs/Derivatives/Tab';
import VariableIncomeTab from './Tabs/VariableIncome/Tab';
import ForeignExchangesTab from './Tabs/ForeignExchanges/Tab';
import { types, tabMinWidth, tabsIdentifiers } from '@config/constants';

const MarketLevel1 = ({
  title,
  subTitle,
  tabs,
  isActive,
  disabledModeData,
  hideGraphicLevelOne,
  tickerData,
  hideTicker,
  showHeaderLogin
}) => {
  const router = useRouter();
  const defaultTabFromUrl = getDefaultTabFromUrl({
    router,
    tabsKeys: getTabsKeys(tabs, 'title'),
    level: 0
  });
  const [activeTab, setActiveTab] = useState(defaultTabFromUrl);
  const defaultSubTabFromUrl = getDefaultTabFromUrl({
    router,
    tabsKeys: getTabsKeys(tabs[defaultTabFromUrl].subTabs, 'title'),
    level: 1
  });

  const [activeSubTab, setActiveSubTab] = useState(defaultSubTabFromUrl);
  useDefaultTabFromUrl([
    tabs[activeTab].title,
    tabs[activeTab].subTabs[activeSubTab].title
  ]);

  let serializerData = {};
  let serializerHideGraphicInfo = {};
  tabs.map((tab) => {
    const marketInfo = disabledModeData?.pdDisableModeN2S.find(
      (e) => e.marketIdentifier === tab.identifier
    );
    serializerData = { ...serializerData, [tab.identifier]: marketInfo };

    const graphicInfo = hideGraphicLevelOne.find(
      (elem) => elem.marketIdentifier === tab.identifier
    );
    serializerHideGraphicInfo = {
      ...serializerHideGraphicInfo,
      [tab.identifier]: graphicInfo
    };
  });

  const [updateTickerData, setUpdateTickerData] = useState();
  const renderedTabs = useMemo(
    () =>
      tabs.map(
        ({ title: tabTitle, identifier, subTabs, generalDescription }) => {
          switch (identifier) {
            case tabsIdentifiers.VARIABLE_INCOME:
              return (
                <Tab
                  className={`market-level-1__tab-${identifier}`}
                  key={identifier}
                  title={tabTitle}
                  minHeight='35'
                >
                  <VariableIncomeTab
                    tabs={subTabs}
                    type={types.RV}
                    generalDescription={generalDescription}
                    defaultTab={defaultSubTabFromUrl}
                    onChangeTab={setActiveSubTab}
                    disabledMode={serializerData[identifier]}
                    setUpdateTickerData={setUpdateTickerData}
                  />
                </Tab>
              );
            case tabsIdentifiers.INDEXES:
              return (
                <Tab
                  className={`market-level-1__tab-${identifier}`}
                  key={identifier}
                  title={tabTitle}
                  minHeight='35'
                >
                  <IndexesTab
                    tabs={subTabs}
                    type={types.INDEXES}
                    selectedTab={activeSubTab}
                    generalDescription={generalDescription}
                    setSelectedTab={setActiveSubTab}
                    disabledMode={serializerData[identifier]}
                    setUpdateTickerData={setUpdateTickerData}
                  />
                </Tab>
              );
            case tabsIdentifiers.FIXED_RENT:
              return (
                <Tab
                  className={`market-level-1__tab-${identifier}`}
                  key={identifier}
                  title={tabTitle}
                  minHeight='35'
                >
                  <FixedRentTab
                    tabs={subTabs}
                    type={types.RF_PUB}
                    defaultTab={defaultSubTabFromUrl}
                    generalDescription={generalDescription}
                    onChangeTab={setActiveSubTab}
                    disabledMode={serializerData[identifier]}
                    hideGraphicLevelOne={serializerHideGraphicInfo[identifier]}
                    setUpdateTickerData={setUpdateTickerData}
                  />
                </Tab>
              );
            case tabsIdentifiers.DERIVATIVES:
              return (
                <Tab
                  className={`market-level-1__tab-${identifier}`}
                  key={identifier}
                  title={tabTitle}
                  minHeight='35'
                >
                  <DerivativesTab
                    tabs={subTabs}
                    type={types.DER}
                    defaultTab={defaultSubTabFromUrl}
                    generalDescription={generalDescription}
                    onChangeTab={setActiveSubTab}
                    disabledMode={serializerData[identifier]}
                    setUpdateTickerData={setUpdateTickerData}
                  />
                </Tab>
              );
            case tabsIdentifiers.FOREIGN_EXCHANGE:
              return (
                <Tab
                  className={`market-level-1__tab-${identifier}`}
                  key={identifier}
                  title={tabTitle}
                  minHeight='35'
                >
                  <ForeignExchangesTab
                    tabs={subTabs}
                    type={types.DIV}
                    defaultTab={defaultSubTabFromUrl}
                    generalDescription={generalDescription}
                    onChangeTab={setActiveSubTab}
                    disabledMode={serializerData[identifier]}
                    setUpdateTickerData={setUpdateTickerData}
                  />
                </Tab>
              );
            default:
              return (
                <Tab
                  className={`market-level-1__tab-${identifier}`}
                  key={identifier}
                  title={tabTitle}
                  minHeight='35'
                >
                  <p>Section</p>
                </Tab>
              );
          }
        }
      ),
    [tabs, activeSubTab]
  );
  return !isActive ? (
    <></>
  ) : (
    <>
      {!hideTicker && (
        <Ticker
          initialData={tickerData}
          updateTickerData={updateTickerData}
          showHeaderLogin={showHeaderLogin}
        />
      )}
      <StyledContainer>
        <Typography type='h5' color='tertiary' as='h2'>
          {title}
        </Typography>
        <Typography type='caption' color='tertiary' as='p'>
          {subTitle}
        </Typography>
        <FancyLine />
        <Tabs
          className='market-level-1__tabs'
          centered={false}
          tabsFluid
          bgColor='localMarket'
          bgColorType='dark'
          defaultValue={defaultTabFromUrl}
          titleFontStyle={{
            fontColor: 'tertiary',
            fontColorType: 'normal',
            fontWeight: 'normal',
            selectedTabFontColor: 'secondary',
            selectedTabFontColorType: 'normal',
            selectedTabFontWeight: '900'
          }}
          lineColor='quaternary'
          lineColorType='normal'
          tabMinWidth={tabMinWidth}
          onChange={(index) => {
            setActiveTab(index);
            if (activeSubTab !== 0) {
              setActiveSubTab(0);
            }
          }}
        >
          {renderedTabs}
        </Tabs>
      </StyledContainer>
    </>
  );
};

MarketLevel1.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      identifier: PropTypes.string,
      subTabs: PropTypes.arrayOf(PropTypes.object).isRequired
    })
  ).isRequired,
  hideGraphicsLevelOne: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      imageUrl: PropTypes.string,
      marketIdentifier: PropTypes.string
    })
  ).isRequired,
  hideTicker: PropTypes.bool.isRequired,
  showHeaderLogin: PropTypes.bool.isRequired
};

export default MarketLevel1;
