import PropTypes from 'prop-types';

import {
  childrenProps,
  colorTypeProps,
  borderRadiusProps
} from '@config/common-propTypes';
import { StyledContainer, StyledChild } from './ImageCard.styled';

const ImageCard = ({
  children,
  img,
  color,
  colorType,
  baseColor,
  borderRadius,
  baseColorType,
  withoutPadding,
  enabledEffects
}) => (
  <StyledContainer
    img={img}
    color={color}
    colorType={colorType}
    baseColor={baseColor}
    borderRadius={borderRadius}
    baseColorType={baseColorType}
    withoutPadding={withoutPadding}
    enabledEffects={enabledEffects}
  >
    <StyledChild>{children}</StyledChild>
  </StyledContainer>
);

ImageCard.propTypes = {
  children: childrenProps.isRequired,
  img: PropTypes.string,
  color: PropTypes.string,
  colorType: colorTypeProps,
  baseColor: PropTypes.string,
  baseColorType: colorTypeProps,
  borderRadius: borderRadiusProps,
  withoutPadding: PropTypes.bool,
  enabledEffects: PropTypes.bool
};

ImageCard.defaultProps = {
  img: undefined,
  color: 'imageCard',
  colorType: 'normal',
  withoutPadding: false,
  borderRadius: 'none',
  baseColorType: 'normal',
  baseColor: 'imageCardBase',
  enabledEffects: true
};

export default ImageCard;
