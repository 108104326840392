import slugify from 'slugify';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Grid } from 'react-styled-flexboxgrid';
import { useState, useEffect, useMemo } from 'react';
import {
  Tab,
  Carousel,
  Container,
  FancyLine,
  Typography
} from '@bvcco/bvc-digital-market-component-library';

import {
  CustomRow,
  StyledRow,
  StyledTabs,
  NewsHeader,
  ClickeableCol
} from './News.styled';
import useRouter from '@hooks/useRouter';
import { setCookie } from '@utils/cookies';
import useBreakpoint from '@hooks/useBreakpoint';
import Novelty from '@components/Novelty/Novelty';
import { isAValidExternalUrl } from '@utils/strings';
import useDefaultTabFromUrl, {
  cleanKey,
  getTabsKeys,
  getDefaultTabFromUrl
} from '@hooks/useDefaultTabFromUrl';
import ImageCard from '@components/ImageCard/ImageCard';
import ButtonLines from '@components/ButtonLines/ButtonLines';
import { noveltyTypes, breakpoints, paths } from '@config/constants';
import { propTypes as noveltyPropTypes } from '@components/Novelty/noveltyPropTypes';

const { SQUARE, RECTANGLE } = noveltyTypes;

const News = ({ news, categories }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const defaultTabFromUrl = getDefaultTabFromUrl({
    router,
    tabsKeys: getTabsKeys(categories, 'name'),
    level: 0,
    translatedQueryParamName: t('newsTab')
  });
  const currentBreakpoint = useBreakpoint();
  const [isMobile, setIsMobile] = useState(false);
  const [activeTab, setActiveTab] = useState(defaultTabFromUrl);

  useDefaultTabFromUrl([categories[activeTab]?.name], {
    translatedQueryParamName: useMemo(() => t('newsTab'), [t]),
    queryParamKey: 'newsTab'
  });

  const colorTypesArray = [
    'imageCard',
    'imageCardBase',
    'imageCardBase',
    'primary',
    'imageCardTertiary'
  ];

  useEffect(() => {
    if (
      currentBreakpoint === breakpoints.LG ||
      currentBreakpoint === breakpoints.MD
    ) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [news, currentBreakpoint]);

  const redirectTo = () => {
    const {
      button: { url },
      name
    } = categories[activeTab];
    if (isAValidExternalUrl(url)) {
      router.push(url);
    }
    setCookie('categoryId', categories[activeTab].id);
    router.push({
      pathname: paths.NEWS,
      asPath: url,
      as: url,
      query: {
        tab: cleanKey(name)
      }
    });
  };

  const noveltyRedirectTo = (novelty) => {
    const {
      button: { url }
    } = categories[activeTab];

    let noveltyUrl = '';

    if (activeTab === 3) {
      noveltyUrl = novelty?.externalUrl;
    } else {
      noveltyUrl = `${url}/${slugify(`${novelty?.title} ${novelty?.id}`, {
        lower: true
      })}`;
    }
    router.push(noveltyUrl);
  };

  const renderNews = (category, areInvestigations) => {
    const filteredCategory = news
      ?.filter((notice) => {
        return notice?.category?.name === category?.name;
      })
      .slice(0, 5);

    if (isMobile) {
      return (
        <Col xs={12} data-testid='carousel-item'>
          <Carousel
            swipeable
            infiniteLoop
            emulateTouch
            showThumbs={false}
            showArrows={false}
            showStatus={false}
          >
            {filteredCategory.map((novelty, index) => (
              <Col
                xs={12}
                key={novelty.id}
                id={`novelty-mobile-item-${index}`}
                onClick={() => noveltyRedirectTo(novelty)}
              >
                <ImageCard
                  withoutPadding
                  color={colorTypesArray[index]}
                  img={novelty?.mainImage?.url}
                >
                  <Novelty
                    {...novelty}
                    index={index}
                    type={SQUARE}
                    isInvestigation={areInvestigations}
                  />
                </ImageCard>
              </Col>
            ))}
          </Carousel>
        </Col>
      );
    }

    return filteredCategory.map((novelty, index) => {
      const noveltyType =
        !isMobile && (index === 1 || index === 2) ? SQUARE : RECTANGLE;
      return (
        <ClickeableCol
          xs={12}
          md={noveltyType === RECTANGLE ? 6 : 3}
          key={novelty?.id}
          id={`novelty-item-${index}`}
          data-testid='novelty-item'
          onClick={() => noveltyRedirectTo(novelty)}
        >
          <ImageCard
            color={colorTypesArray[index]}
            withoutPadding
            img={novelty?.mainImage?.url}
          >
            <Novelty
              {...novelty}
              index={index}
              type={noveltyType}
              breakpoint={currentBreakpoint || ''}
              isInvestigation={areInvestigations}
            />
          </ImageCard>
        </ClickeableCol>
      );
    });
  };

  return (
    <>
      <Container>
        <NewsHeader>
          <Typography color='tertiary' type='h5'>
            {t('informationOfInterest')}
          </Typography>
          <FancyLine />
        </NewsHeader>
      </Container>
      <StyledTabs
        renderSeparatorBorders
        fullWidthHeader
        dividerHeight='17px'
        tabsFluid
        centered
        headerWrapped
        borderColor='disabled'
        borderColorType='normal'
        titleFontStyle={{
          fontColor: 'tertiary',
          fontColorType: 'normal',
          fontWeight: 'normal',
          selectedTabFontColor: 'secondary',
          selectedTabFontColorType: 'normal',
          selectedTabFontWeight: '900'
        }}
        lineColor='quaternary'
        lineColorType='normal'
        breakpoint={currentBreakpoint || breakpoints.SM}
        defaultValue={defaultTabFromUrl}
        value={activeTab}
        onChange={setActiveTab}
      >
        {categories.map((category, index) => (
          <Tab
            key={category.id}
            id={`tab-item-${index}`}
            title={category.name}
            titleTag='h3'
          >
            <Grid fluid>
              <StyledRow>{renderNews(category, index === 2)}</StyledRow>
            </Grid>
          </Tab>
        ))}
      </StyledTabs>
      <Container>
        <CustomRow isMobile={isMobile}>
          <ButtonLines
            id={`show-more-${activeTab}`}
            onClick={redirectTo}
            size='large'
          >
            {categories[activeTab]?.button?.text}
          </ButtonLines>
        </CustomRow>
      </Container>
    </>
  );
};

News.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      author: noveltyPropTypes.author,
      id: noveltyPropTypes.id.isRequired,
      subtitle: noveltyPropTypes.subtitle,
      mainImage: noveltyPropTypes.mainImage,
      title: noveltyPropTypes.title.isRequired,
      dateAndTime: noveltyPropTypes.dateAndTime,
      externalUrl: noveltyPropTypes.externalUrl
    })
  ).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: noveltyPropTypes.id,
      name: PropTypes.string,
      button: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string
      })
    })
  ).isRequired
};

export default News;
