export default {
  accesibilityBar: {
    virtualOffice: 'Virtual office'
  },
  name: 'Name',
  registrationYear: 'Registration year',
  listingDate: 'Listing Date',
  industry: 'Industry',
  city: 'City',
  showMore: 'Show more',
  country: 'Country',
  countryETF: 'Country',
  mainStockExchange: 'Stock exchange',
  sponsorName: 'Sponsor name',
  marketCap: 'Market Capitalization',
  allEvents: 'All events',
  closedMarket: 'Closed market',
  closingPrice: 'Closing price',
  contactOur: 'Contact our Stock Market Information Center at',
  couponFrequenciesRfPriv: {
    none: '-',
    daily: 'Daily',
    monthly: 'Monthly',
    bimonthly: 'Bimonthly',
    quarterly: 'Quarterly',
    everyFourMonths: 'Every four months',
    semiannual: 'Semiannual',
    annual: 'Annual',
    biannual: 'Biannual',
    periodic: 'Periodic'
  },
  days: 'Days',
  date: 'Date',
  delay: 'delay',
  deleteFilters: 'Delete filters',
  emailValidation: 'The email must contain an @ and .com',
  dataProcessingPoliciesValidation:
    'You must accept the data treatment policy to continue',
  emailIsRequired: 'Enter your email',
  lastNameIsRequired: 'Enter your lastname',
  nameIsRequired: 'Enter your name',
  fieldsRequiredContactUs: 'Data marked with an asterisk (*) are required.',
  contactFormIsRequired:
    'Please complete this field, for BVC it ​​is important to know your request in detail, so we can provide you with the appropriate support.',
  captchaIsRequired: 'The captcha is required',
  download: 'Download',
  onlyLetters: 'This field must contain only letters',
  onlyNumbers: 'This field must contain only numbers',
  invalidCharacters: 'This field contains illegal characters',
  intervalFilters: {
    '1D': '1D',
    '1S': '1W',
    '1M': '1M',
    '3M': '3M',
    '6M': '6M',
    '1A': '1Y',
    '5A': '5Y',
    Max: 'Max'
  },
  tooLongField: 'This field exceeds the maximum number of characters',
  filter: 'Filter',
  filters: 'Filters',
  findIssuer: 'Find issuer',
  findIssuerOrMnemonic: 'Find the issuer or mnemonic',
  findTitleOrWord: 'Find the title or keyword',
  find: 'Search',
  findIssuerOrMnemonicRV: 'Find the issuer, name or mnemonic',
  findIssuerOrUnderlying: 'Find the mnemonic or underlying',
  home: 'Home',
  hour: 'Hour',
  volume: 'Volume',
  contracts: 'Contracts',
  previousClosing: 'Previous closing',
  referencePrice: 'Reference price',
  referenceRate: 'Tasa de referencia',
  online: 'Online',
  indexRv: {
    tableColumns: {
      actualValue: 'Actual value',
      name: 'Name',
      variation: 'Variation'
    },
    tabs: {
      actualValue: 'Valor hoy',
      shareholders: 'Accionarios',
      variacion: 'Variation'
    }
  },
  makeGoodDecision: 'I want to make good decisions',
  marketType: 'Market type',
  maxSelectedItemsError: 'Max selected options limit reached',
  minutes: 'minutes',
  mnemonic: 'Mnemonic',
  month: 'Month',
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December'
  },
  downloadPdf: 'Download PDF',
  informationOfInterest: 'Information of interest',
  followUs: 'Follow us:',
  relatedInvestigations: 'Previous investigations',
  relatedNews: 'Related News',
  noEventsFound: 'No events were found on the selected date',
  noRecordFound: 'There are no records for this date',
  noInformationAvailable: 'There is no information available with that term',
  noFiguresArePresented:
    'No figures are presented to show you as there are no movements in the market.',
  noFiguresWithSelectedFilter:
    'Figures are not presented to show you with the selected filters',
  noRecordsFoundByCategory:
    'No records are presented for the selected category',
  noTradingDay: 'Non-trading day',
  thereAreNoMovements: 'There are no movements in the market',
  noOptions: 'No records',
  openMarket: 'Open market',
  operationType: 'Type of operation',
  instrumentType: 'Type of instrument',
  optionType: 'Type of option',
  price: 'Price',
  prices: 'Prices',
  rate: 'Rate',
  rateOrPrice: 'Rate / Price',
  showMoreDetails: 'Show more details',
  search: 'Search',
  simultaneous: 'Simultaneous',
  simultaneas: 'Simultáneas',
  tables: {
    moreInfo: 'More information',
    thousandsOfMillions: 'Thousands of millions'
  },
  thousandsOfDollars: 'USD',
  millionsOfPesos: 'COP',
  today: 'TODAY',
  todayIsNonStock: 'Today is a non-stock market day',
  toDownloadInformation: 'To download information more than',
  tradedVolume: 'Traded volume',
  tradingDay: 'Trading day',
  currentTrm: 'Current TRM',
  trm: 'TRM',
  suppliedBy: 'suppield by',
  typeClass: 'Type class',
  valueToday: 'Value today',
  variation: 'Variation',
  yearsOfAntiguaty: 'years old',
  category: 'Category',
  openPrice: 'Opening price',
  lastPrice: 'Last price',
  lastRate: 'Ultima tasa',
  maxPrice: 'Maximum price',
  averagePrice: 'Average price',
  minPrice: 'Minimum price',
  lastVolume: 'Last volume',
  maxVolume: 'Maximum Volume',
  averageVolume: 'Average volume',
  minVolume: 'Minimum volume',
  marketSpot: 'Spot Market',
  registrationOperations: 'Registration operations',
  exchangeRate: 'Exchange rate',
  settlementDate: 'Settlement date',
  volumeUSD: 'Volume USD',
  volumeCOP: 'Volume COP',
  exchangeMarket: 'Exchange market',
  freeMarket: 'Free market',
  totalVolumeUSD: 'Total Volume USD',
  totalVolumeCOP: 'Total Volume COP',
  market: 'Market',
  dollar: 'Dollar',
  'foreign-exchange': 'divisas',
  'variable-income': 'renta-variable',
  indexes: 'indices',
  'fixed-rent': 'renta-fija',
  indexesPath: '/indexes',
  internalError: {
    title: 'Oops! An unexpected error occurred',
    description: 'we are working to fix it. Try again later.'
  },
  derivativesPath: '/derivatives',
  derivatives: 'derivados',
  goBack: 'Go Back',
  lastNegotiation: 'Last negotiation',
  baskets: 'Baskets',
  from: 'From',
  to: 'To',
  dateRange: 'Date range',
  datesRange: 'Date range',
  generateHistorical: 'Generate historical',
  selectDate: 'Select Date',
  selectDates: 'Select Dates',
  marketLvl2Route: '/local-market-online',
  derivativesN2: 'derivatives_futures',
  variableIncomeN2: 'equity_local-market',
  indexesN2: 'indexes_equity',
  value: 'Value',
  yearPlaceholder: 'yyyy',
  graphicDescription:
    'The graph is presented from the birth of the contract. The bars indicate the trading volume of the security, and its scale is on the vertical axis on the left.',
  routes: {},
  toDownload: 'To download',
  acciones: 'Shares',
  etfs: 'ETFs',
  by: 'by',
  variableIncomePath: {
    path: '/variable-income',
    subPaths: {
      local_market: 'local-market',
      colombian_global_market: 'colombian-global-market'
    },
    tabs: {
      information: '#information',
      summary: '#summary'
    }
  },
  issuerInformationPath: {
    path: '/issuer-information'
  },
  fixedRentPath: {
    path: '/fixed-rent',
    tab: 'fixed-income',
    subPaths: {
      public_debt_public_segment: 'public-debt-public-segment',
      public_debt_private_segment: 'public-debt-private-segment',
      corporate_debt: 'corporate-debt'
    }
  },
  dividend: 'Dividend',
  couponFrequency: {
    N: '-',
    D: 'Daily',
    Mo: 'Monthly',
    BM: 'Bimonthly',
    QR: 'Quarterly',
    FM: 'Every four months',
    SA: 'Semiannual',
    Yr: 'Annual',
    BY: 'Biannual',
    P: 'Periodic'
  },
  newsTab: 'newsTab',
  notApply: 'Not apply'
};
