import Calendar from 'react-calendar';
import styled, { css } from 'styled-components';

const getEventsStyles = ({ events, theme }) => {
  let styles = '';
  events.forEach((event) => {
    styles += `
      abbr[aria-label='${event}'] {
        font-weight: bold;
        color: ${theme.colors.font.secondary.normal};
      }
    `;
  });
  return styles;
};

export const StyleContainer = styled.div`
  position: relative;
`;

export const StyleCloseContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.utils.spacing(2)};
  right: ${({ theme }) => theme.utils.spacing(6)};
`;

export default styled(Calendar)`
  width: 350px;
  max-width: 100%;
  padding: ${({ theme }) => theme.utils.spacing(5)};
  font-family: ${({ theme }) => theme.font.family.primary};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  line-height: ${({ theme }) => theme.font.lineHeight.normal};
  background-color: ${({ theme }) => theme.colors.bg.calendar.dark};
  box-shadow: ${({ theme }) => theme.boxShadow.calendar.normal};
  &,
  & *,
  & *:before,
  & *:after {
    box-sizing: border-box;
  }
  button {
    margin: 0;
    border: 0;
    outline: none;
  }
  button:enabled:hover {
    cursor: pointer;
  }

  /* Navigation */
  .react-calendar__tile:disabled {
    background-color: transparent !important;
    color: ${({ theme }) => theme.colors.disableDate.light};
  }
  .react-calendar__navigation {
    display: flex !important;
    margin-bottom: ${({ theme }) => theme.utils.spacing(1)};
    > button {
      color: ${({ theme }) => theme.colors.font.calendar.normal};
      min-width: 44px;
      background: none;
      background-clip: content-box, padding-box;
      font-size: ${({ theme }) => theme.font.size.h6};
      padding: ${({ theme }) => theme.utils.spacing(1)};
      border-radius: ${({ theme }) => theme.borderRadius.total};
      [disabled] {
        background-color: ${({ theme }) => theme.colors.bg.disabled.normal};
      }
    }
    .react-calendar__navigation__label__labelText {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.bg.primary.normal};
      font-size: ${({ theme }) => theme.font.size.paragraph1};
      text-transform: capitalize;
    }
    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev2-button {
      display: ${({ showYearControls }) =>
        showYearControls ? 'block' : 'none'};
    }
  }
  /* End Navigation */

  .react-calendar--doubleView {
    width: 700px;
    .react-calendar__viewContainer {
      display: flex;
      margin: ${({ theme }) => theme.utils.spacing(-1.9)};
    }
    .react-calendar__viewContainer > * {
      width: 50%;
      margin: ${({ theme }) => theme.utils.spacing(1.9)};
    }
  }
  .react-calendar__month-view__weekdays {
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    font-size: ${({ theme }) => theme.font.size.paragraph2};
    color: ${({ theme }) => theme.colors.font.calendar.normal};
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.border.divider.normal};
    margin-bottom: 4px;
    .react-calendar__month-view__weekdays__weekday {
      padding: ${({ theme }) => theme.utils.spacing(2)};
    }
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
    .react-calendar__tile {
      display: flex;
      font-size: ${({ theme }) => theme.utils.spacing(3)};
      align-items: center;
      justify-content: center;
      padding: calc(${({ theme }) => theme.utils.spacing(3)} / 0.75)
        calc(${({ theme }) => theme.utils.spacing(2)} / 0.75);
    }
  }
  .react-calendar__month-view__days__day {
    color: ${({ theme }) => theme.colors.font.calendar.normal};
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${({ theme }) => theme.colors.font.disabledcalendar.normal};
  }

  .react-calendar__century-view__decades__decade,
  .react-calendar__decade-view__years__year,
  .react-calendar__year-view__months__month {
    color: ${({ theme }) => theme.colors.font.tertiary.normal};
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: ${({ theme }) => theme.utils.spacing(8, 2)};
  }

  .react-calendar__tile {
    max-width: 100%;
    margin: 4px 0px;
    background: none;
    text-align: center;
    width: 44px;
    height: 44px;
    padding: 0;
    border: 2px solid transparent;
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    background-clip: content-box;
    text-transform: capitalize;
    :disabled {
      background-color: ${({ theme }) => theme.colors.bg.danger.normal};
    }
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-calendar__tile--hasActive {
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.colors.font.active.normal};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.font.active.normal};
  }
  .react-calendar__tile--active {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.font.active.normal};
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    border: 2px solid ${({ theme }) => theme.colors.bg.secondary.normal};
  }
  ${({ theme, showHighLightDayNow }) =>
    showHighLightDayNow &&
    css`
      .react-calendar__tile--now {
        font-weight: bolder;
        color: ${theme.colors.bg.active.normal};
      }
    `}
  ${(props) => getEventsStyles(props)}
`;

export const StyledNavigationLabel = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 900;
  font-size: ${({ theme }) => theme.font.size.paragraph1};
`;

export const StyledYearLabel = styled.span`
  font-size: ${({ theme }) => theme.font.size.caption};
  margin-top: ${({ theme }) => theme.utils.spacing(1)};
  font-weight: normal;
`;
