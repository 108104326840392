import PropTypes from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';

import {
  formatEvents,
  defaultFormatMonthYear,
  defaultFormatShortWeekday
} from '@utils/dates';
import StyledCalendar, {
  StyleContainer,
  StyledYearLabel,
  StyleCloseContainer,
  StyledNavigationLabel
} from './Calendar.styled';
import { languages } from '@config/constants';
import CloseIcon from '@components/Icons/CloseIcon';

const Calendar = ({
  events,
  locale,
  onClose,
  formatMonthYear,
  formatShortWeekday,
  showYearControls,
  showCloseIcon,
  showHighLightDayNow,
  withAlternativeNavigationLabel,
  ...rest
}) => {
  const [eventsFormatted, setEventsFormatted] = useState(
    formatEvents({ events, locale })
  );
  const [applyEvents, setApplyEvents] = useState(false);

  const navigationLabel = useMemo(() => {
    if (withAlternativeNavigationLabel) {
      return ({ view, label }) => {
        if (view === 'month') {
          const [year, month] = label.split(' ');
          return (
            <StyledNavigationLabel>
              {month} <StyledYearLabel>{year}</StyledYearLabel>
            </StyledNavigationLabel>
          );
        }
        return label;
      };
    }
    return null;
  }, [withAlternativeNavigationLabel]);

  useEffect(() => {
    if (!applyEvents) setApplyEvents(true);
    setEventsFormatted(formatEvents({ events, locale }));
  }, [events, locale]);

  return (
    applyEvents && (
      <StyleContainer>
        <StyleCloseContainer className='close_contain_filter'>
          {showCloseIcon ? (
            <CloseIcon
              className='filters__close-icon-calendar'
              onClick={() => onClose()}
            />
          ) : null}
        </StyleCloseContainer>
        <StyledCalendar
          {...rest}
          showHighLightDayNow={showHighLightDayNow}
          showYearControls={showYearControls}
          formatMonthYear={formatMonthYear || defaultFormatMonthYear}
          formatShortWeekday={formatShortWeekday || defaultFormatShortWeekday}
          locale={locale}
          events={eventsFormatted}
          navigationLabel={navigationLabel}
        />
      </StyleContainer>
    )
  );
};

Calendar.propTypes = {
  events: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  locale: PropTypes.string,
  formatMonthYear: PropTypes.func,
  formatShortWeekday: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  showYearControls: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  showHighLightDayNow: PropTypes.bool,
  withAlternativeNavigationLabel: PropTypes.bool
};

Calendar.defaultProps = {
  events: [],
  locale: languages.ES,
  formatMonthYear: undefined,
  formatShortWeekday: undefined,
  showYearControls: false,
  showCloseIcon: true,
  showHighLightDayNow: true,
  withAlternativeNavigationLabel: false
};

export default Calendar;
