/* istanbul ignore file */
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledSvg = styled.svg`
  cursor: pointer;
`;

const CloseIcon = ({ onClick, color }) => (
  <StyledSvg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
  >
    <path
      d='M10.6491 9.0146L17.6579 2.00554C18.114 1.54963 18.114 0.812491 17.6579 0.356581C17.202 -0.099329 16.4649 -0.099329 16.009 0.356581L8.9999 7.36563L1.99106 0.356581C1.53493 -0.099329 0.798003 -0.099329 0.342093 0.356581C-0.114031 0.812491 -0.114031 1.54963 0.342093 2.00554L7.35094 9.0146L0.342093 16.0236C-0.114031 16.4796 -0.114031 17.2167 0.342093 17.6726C0.5693 17.9 0.868044 18.0143 1.16657 18.0143C1.46511 18.0143 1.76364 17.9 1.99106 17.6726L8.9999 10.6636L16.009 17.6726C16.2364 17.9 16.5349 18.0143 16.8334 18.0143C17.132 18.0143 17.4305 17.9 17.6579 17.6726C18.114 17.2167 18.114 16.4796 17.6579 16.0236L10.6491 9.0146Z'
      fill={color}
    />
  </StyledSvg>
);

CloseIcon.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string
};

CloseIcon.defaultProps = {
  onClick: () => {},
  color: 'white'
};

export default CloseIcon;
